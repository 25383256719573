import { ArmorBase, WeaponBase } from '../../common/types';
import { useAppSelector } from '../../store/store';
import { selectorArmorBases, selectorFindRuneword, selectorWeaponBases } from '../../store/activeData/selector';
import { useMemo } from 'react';

export const useRunewordBases = (runewordId?: number) => {
  const armorBases = useAppSelector(selectorArmorBases);
  const weaponBases = useAppSelector(selectorWeaponBases);
  const runeword = useAppSelector(state => selectorFindRuneword(state, runewordId));

  return useMemo(() => {
    let armors: ArmorBase[] = [];
    let weapons: WeaponBase[] = [];

    if (runeword) {
      if (runeword.basetype.includes('All Weapons')) {
        weapons = [...weaponBases.filter(wb => wb.sockets_hell && wb.sockets_hell >= runeword.sockets)];
      }

      if (runeword.basetype.includes('Melee Weapons')) {
        weapons = [
          ...weapons,
          ...weaponBases.filter(
            wb => wb.sockets && wb.sockets >= runeword.sockets && wb.qualifiedType !== 'Bows' && wb.qualifiedType !== 'Crossbows',
          ),
        ];
      }

      if (runeword.basetype.includes('Katars')) {
        weapons = weaponBases.filter(wb => wb.sockets && wb.sockets >= runeword.sockets && wb.qualifiedType === 'Assassin Katars');
        return { weapons, armors };
      }


      weapons = [
        ...weapons,
        ...weaponBases.filter(wb => wb.sockets && wb.sockets >= runeword.sockets && runeword.basetype.includes(wb.qualifiedType)),
      ];

      armors = [
        ...armorBases.filter(
          ab =>
            (ab.sockets && ab.sockets >= runeword.sockets && runeword.basetype.includes(ab.qualifiedType)) ||
            (runeword.basetype.includes('Helms') && (ab.qualifiedType === 'Druid Pelts' || ab.qualifiedType === 'Barbarian Helms')),
        ),
      ];
    }
    return { armors, weapons };
  }, [runeword, armorBases, weaponBases]);
};
