import React, { useContext, useMemo, useState } from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonSearchbar, useIonRouter } from '@ionic/react';
import { appRoutes } from '../../AppRoutes';
import { useAppSelector } from '../../store/store';
import { selectorUniques } from '../../store/activeData/selector';
import { ViewUnique } from '../../common/components/ViewUnique/ViewUnique';
import { HolyGraiLContext } from '../../common/providers/HolyGrailProvider/HolyGrailProvider';
import { settingsOutline } from 'ionicons/icons';
import { selectorItemCatalogSettings } from '../../store/settings/selectors';

export const UniqueCatalog: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const uniqueItems = useAppSelector(selectorUniques);
  const { grailUniques } = useContext(HolyGraiLContext);
  const showGrailProgress = useAppSelector(selectorItemCatalogSettings).showUniqueGrailProgress;
  const router = useIonRouter();

  const searchResults = useMemo(() => {
    if (!searchTerm) return undefined;

    const resultIds: number[] = [];

    for (const item of uniqueItems) {
      if (item.name.toLowerCase().includes(searchTerm)) {
        resultIds.push(item.id);
        continue;
      }
      for (const prop of item.props) {
        if (prop.toLowerCase().includes(searchTerm)) {
          resultIds.push(item.id);
          break;
        }
      }
    }
    return resultIds;
  }, [searchTerm, uniqueItems]);

  const calcGrailProgress = (catId: number) => {
    if (!showGrailProgress) return null;
    const all = uniqueItems.filter(u => u.category_id === catId);
    const found = all.reduce((total, current) => (grailUniques.some(u => u.item_id === current.id) ? total + 1 : total), 0);
    return `(${found}/${all.length})`;
  };

  return (
    <AppPage
      padding={true}
      navBar={true}
      title={'Unique Items'}
      headerContent={
        <IonSearchbar
          animated={true}
          placeholder={'Search anything...'}
          mode={'ios'}
          color={'medium'}
          debounce={200}
          onIonInput={e => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setSearchTerm(e.detail?.value.toLowerCase() ?? ('' as string));
          }}
        />
      }
      buttons={
        <IonIcon icon={settingsOutline} style={{ fontSize: '20px' }} onClick={() => router.push(appRoutes.settings.path('item-catalog'))} />
      }
    >
      {!searchTerm && (
        <>
          <IonList lines={'none'} inset>
            <IonListHeader className={'unique no-inset'}>Class Specific</IonListHeader>
            <IonItem routerLink={appRoutes.catalog.children.view.path(0, 'Unique', 'Amazon')}>
              <IonLabel>
                Amazon <span className={'unique f-14'}>{calcGrailProgress(0)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(1, 'Unique', 'Assassin')}>
              <IonLabel>
                Assassin <span className={'unique f-14'}>{calcGrailProgress(1)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(2, 'Unique', 'Barbarian')}>
              <IonLabel>
                Barbarian <span className={'unique f-14'}>{calcGrailProgress(2)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(3, 'Unique', 'Druid')}>
              <IonLabel>
                Druid <span className={'unique f-14'}>{calcGrailProgress(3)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(4, 'Unique', 'Paladin')}>
              <IonLabel>
                Paladin <span className={'unique f-14'}>{calcGrailProgress(4)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(5, 'Unique', 'Necromancer')}>
              <IonLabel>
                Necromancer <span className={'unique f-14'}>{calcGrailProgress(5)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(6, 'Unique', 'Sorceress')}>
              <IonLabel>
                Sorceress <span className={'unique f-14'}>{calcGrailProgress(6)}</span>
              </IonLabel>
            </IonItem>
          </IonList>

          <IonList lines={'none'} inset>
            <IonListHeader className={'unique no-inset'}>Armor</IonListHeader>
            <IonItem routerLink={appRoutes.catalog.children.view.path(12, 'Unique', 'Helms')}>
              <IonLabel>
                Helms <span className={'unique f-14'}>{calcGrailProgress(12)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(13, 'Unique', 'Armors')}>
              <IonLabel>
                Armors <span className={'unique f-14'}>{calcGrailProgress(13)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(14, 'Unique', 'Shields')}>
              <IonLabel>
                Shields <span className={'unique f-14'}>{calcGrailProgress(14)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(15, 'Unique', 'Gloves')}>
              <IonLabel>
                Gloves <span className={'unique f-14'}>{calcGrailProgress(15)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(16, 'Unique', 'Boots')}>
              <IonLabel>
                Boots <span className={'unique f-14'}>{calcGrailProgress(16)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(17, 'Unique', 'Belts')}>
              <IonLabel>
                Belts <span className={'unique f-14'}>{calcGrailProgress(17)}</span>
              </IonLabel>
            </IonItem>
          </IonList>

          <IonList lines={'none'} inset>
            <IonListHeader className={'unique no-inset'}>Weapons</IonListHeader>
            <IonItem routerLink={appRoutes.catalog.children.view.path(18, 'Unique', 'Axes')}>
              <IonLabel>
                Axes <span className={'unique f-14'}>{calcGrailProgress(18)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(19, 'Unique', 'Bows')}>
              <IonLabel>
                Bows <span className={'unique f-14'}>{calcGrailProgress(19)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(20, 'Unique', 'Crossbows')}>
              <IonLabel>
                Crossbows <span className={'unique f-14'}>{calcGrailProgress(20)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(21, 'Unique', 'Daggers')}>
              <IonLabel>
                Daggers <span className={'unique f-14'}>{calcGrailProgress(21)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(22, 'Unique', 'Javelins')}>
              <IonLabel>
                Javelins <span className={'unique f-14'}>{calcGrailProgress(22)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(23, 'Unique', 'Maces')}>
              <IonLabel>
                Maces <span className={'unique f-14'}>{calcGrailProgress(23)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(31, 'Unique', 'Hammers')}>
              <IonLabel>
                Hammers <span className={'unique f-14'}>{calcGrailProgress(31)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(24, 'Unique', 'Polearms')}>
              <IonLabel>
                Polearms <span className={'unique f-14'}>{calcGrailProgress(24)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(25, 'Unique', 'Scepters')}>
              <IonLabel>
                Scepters <span className={'unique f-14'}>{calcGrailProgress(25)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(26, 'Unique', 'Spears')}>
              <IonLabel>
                Spears <span className={'unique f-14'}>{calcGrailProgress(26)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(27, 'Unique', 'Staves')}>
              <IonLabel>
                Staves <span className={'unique f-14'}>{calcGrailProgress(27)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(28, 'Unique', 'Swords')}>
              <IonLabel>
                Swords <span className={'unique f-14'}>{calcGrailProgress(28)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(29, 'Unique', 'Throwing Weapons')}>
              <IonLabel>
                Throwing Weapons <span className={'unique f-14'}>{calcGrailProgress(29)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(30, 'Unique', 'Wands')}>
              <IonLabel>
                Wands <span className={'unique f-14'}>{calcGrailProgress(30)}</span>
              </IonLabel>
            </IonItem>
          </IonList>

          <IonList lines={'none'} inset>
            <IonListHeader className={'unique no-inset'}>Others</IonListHeader>
            <IonItem routerLink={appRoutes.catalog.children.view.path(7, 'Unique', 'Rings')}>
              <IonLabel>
                Rings <span className={'unique f-14'}>{calcGrailProgress(7)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(8, 'Unique', 'Amulets')}>
              <IonLabel>
                Amulets <span className={'unique f-14'}>{calcGrailProgress(8)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(9, 'Unique', 'Charms')}>
              <IonLabel>
                Charms <span className={'unique f-14'}>{calcGrailProgress(9)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(10, 'Unique', 'Jewels')}>
              <IonLabel>
                Jewels <span className={'unique f-14'}>{calcGrailProgress(10)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(11, 'Unique', 'Circlets')}>
              <IonLabel>
                Circlets <span className={'unique f-14'}>{calcGrailProgress(11)}</span>
              </IonLabel>
            </IonItem>
          </IonList>
        </>
      )}

      {searchTerm && searchResults && (
        <>
          {searchResults.map((r, i) => (
            <ViewUnique key={`search-item-${i}`} id={r} />
          ))}
        </>
      )}
    </AppPage>
  );
};
